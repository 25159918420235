import * as React from 'react';
declare let window: any;
const withAuth = (Component: any) => {
  return class WithAuth extends React.PureComponent<any, any> {
    state = {
      isAuthenticated: false,
      isLoading: true,
      isProfileCreated: false,
    };

    async componentDidMount() {
      const storage = window.localStorage;
      const isAuthenticated =
        storage?.getItem('id') && storage?.getItem('authToken');
      const isProfileCreated = storage?.getItem('isProfileCreated');
      this.setState({
        isLoading: false,
        isAuthenticated,
        isProfileCreated,
      });
    }

    render() {
      const { isLoading, isAuthenticated, isProfileCreated } = this.state;
      if (isLoading) {
        return null;
      }
      return (
        <Component
          {...this.props}
          authenticated={isAuthenticated}
          isProfileCreated={isProfileCreated}
        />
      );
    }
  };
};

export default withAuth;

import { SET_USER_DETAILS } from './action.constants';

const defaultState: any = {
  userDetails: {
    name: localStorage.getItem('name'),
    profilePicUrl: localStorage.getItem('profilePicUrl'),
    id: localStorage.getItem('id'),
    wallet: localStorage.getItem('wallet'),
  },
};
export default (state = defaultState, action: any) => {
  // eslint-disable-line
  switch (action.type) {
    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.data,
      };
    }

    default:
      return state;
  }
};

import { Tooltip } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { iconForName } from './MyDesk.constants';

const styles = {
  appItem: {
    height: '48px',
    width: '48px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FCFCFC',
    borderRadius: '4px',
    marginRight: '8px',
    cursor: 'pointer',
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.06)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const MyApps = (props) => {
  const { classes, apps } = props;

  return (
    <div style={{ display: 'flex', marginBottom: '20px' }}>
      {apps?.map((item, index) => (
        <Tooltip title={item?.type} key={index}>
          <a className={classes.appItem} href={item.link} target='_blank'>
            <div>
              <img src={iconForName[item.name]} alt='' />
            </div>
          </a>
        </Tooltip>
      ))}
    </div>
  );
};

export default withStyles(styles)(MyApps);

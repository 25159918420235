import * as React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import App from './App';
import Login from './components/Login';
import { getLoginRoute, getLogoutRoute } from './routing/utils';
import Logout from './components/Logout';
import { BrowserRouter } from 'react-router-dom';
import GauthPage from './components/GauthPage';

const Main = () => {
  return (
    <BrowserRouter basename='/'>
      <Switch>
        <Route path={getLoginRoute()} exact component={Login} />
        <Route path={getLogoutRoute()} exact component={Logout} />
        <Route path='/oauth' exact component={GauthPage} />
        <Route component={App} />
      </Switch>
    </BrowserRouter>
  );
};

export default withRouter(Main);

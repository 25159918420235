export const getLoginRoute = () => {
  return '/login';
};

export const getLogoutRoute = () => {
  return '/logout';
};

export const getCreateProfileRoute = () => {
  return '/create-profile';
};

export const getMyDeskRoute = () => {
  return '/my-desk';
};

export const getLookAroundRoute = () => {
  return '/look-around';
};

export const getOurPreciousRoute = () => {
  return '/our-precious';
};

export const getShipsymatesRoute = () => {
  return '/shipsymates';
};

export const getShipsyinNewsRoute = () => {
  return '/shipsy-in-news';
};

export const getChillaxRoute = () => {
  return '/chillax';
};

export const getBeerforCheerRoute = () => {
  return '/beer-for-cheer';
};

export const getGalleryRoute = () => {
  return '/gallery';
};

export const getSujhavPetiRoute = () => {
  return '/sujhav-peti';
};

export const getPolicyRoute = () => {
	return '/policy';
  };
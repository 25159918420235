import todosselected from '../icons/todosselected.svg';
import todosunselected from '../icons/todosunselected.svg';
import reminderselected from '../icons/reminderselected.svg';
import reminderunselected from '../icons/reminderunselected.svg';
import courses from '../icons/courses.svg';
import event from '../icons/event.svg';
import myapps from '../icons/myapps.svg';
import photo from '../icons/photo.svg';
import sheets from '../icons/sheets.svg';

import shipsyIcon from '../icons/Shipsyio.svg';
import gmailIcon from '../icons/Gmail.svg';
import confluenceIcon from '../icons/Confluence.svg';
import jiraIcon from '../icons/Jira.svg';
import driveIcon from '../icons/Drive.svg';
import docIcon from '../icons/Docs.svg';
import githubIcon from '../icons/Githu.svg';
import internalDashboardIcon from '../icons/Internal Dashboard.svg';
import lmsIcons from '../icons/LMS.svg';
import slideIcons from '../icons/Slide.svg';
import calendarIcon from '../icons/Calendar.svg';
import gmeetIcon from '../icons/Meet.svg';
import kikaIcon from '../icons/Keka.svg';
import invisionIcon from '../icons/Invision.svg';
import sheetsIcon from '../icons/Sheet.svg';
import Reward from '../icons/Reward.svg';

export const myDeskLabels = [
  {
    selected: reminderselected,
    unselected: reminderunselected,
    title: 'Reminders',
  },
  {
    selected: photo,
    unselected: photo,
    title: 'Photo Frame',
  },
  {
    selected: todosselected,
    unselected: todosunselected,
    title: 'My To Dos',
  },
  {
    selected: courses,
    unselected: courses,
    title: 'My Courses',
  },
  {
    selected: event,
    unselected: event,
    title: 'Event',
  },
  {
    selected: myapps,
    unselected: myapps,
    title: 'My Apps',
  },
  {
    selected: sheets,
    unselected: sheets,
    title: 'My Sheets & Slides',
  },
  {
    selected: Reward,
    unselected: Reward,
    title: 'My Awards',
  },
];

const appNameList = [
  ['Shipsy Website', shipsyIcon],
  ['Gmail', gmailIcon],
  ['Confluence', confluenceIcon],
  ['Jira', jiraIcon],
  ['Invision', invisionIcon],
  ['Keka', kikaIcon],
  ['Google Meet', gmeetIcon],
  ['Calendar', calendarIcon],
  ['Sheet', sheetsIcon],
  ['Slide', slideIcons],
  ['LMS', lmsIcons],
  ['Internal Dashboard', internalDashboardIcon],
  ['Github', githubIcon],
  ['Google docs', docIcon],
  ['Drive', driveIcon],
];

export const myApps = appNameList.map((item) => {
  return {
    name: item[0],
    icon: item[1],
  };
});

export const iconForName = {};
appNameList.map((item) => {
  iconForName[item[0]] = item[1];
});

export const reminderFrequncies = [
  { key: 'daily', title: 'Daily' },
  { key: 'weekly', title: 'Weekly' },
  { key: 'monthly', title: 'Monthly' },
  { key: 'yearly', title: 'Yearly' },
];

export const buttonStyle = (
  color: string,
  backgroundColor: string,
  border: string
) => ({
  color,
  backgroundColor,
  border,
  '&:focus': {
    color,
    backgroundColor,
    border,
  },
  '&:hover': {
    color: '#111111',
    backgroundColor: '#FFD17B',
    border: '1px solid #FFD17B',
  },
});

import * as React from 'react';
import { getLoginRoute } from '../routing/utils';
import { Redirect } from 'react-router';

const Logout = () => {
  const [logout, setLogout] = React.useState(false);

  React.useEffect(() => {
    localStorage.removeItem('authToken');
    setLogout(true);
  }, []);
  if (logout) {
    return <Redirect to={{ pathname: getLoginRoute() }} />;
  }
  return <div></div>;
};

export default Logout;

import * as React from 'react';
import { Switch, Route } from 'react-router';
import {
  getBeerforCheerRoute,
  getChillaxRoute,
  getCreateProfileRoute,
  getGalleryRoute,
  getLookAroundRoute,
  getMyDeskRoute,
  getOurPreciousRoute,
  getPolicyRoute,
  getShipsyinNewsRoute,
  getShipsymatesRoute,
  getSujhavPetiRoute,
} from './utils';
import Loader from '../icons/Loader.gif';
import Policy from '../components/Policy';

const DefaultPage = React.lazy(() => import('../components/DefaultPage'));
const CreateProfilePage = React.lazy(() =>
  import('../components/CreateProfile')
);
const LookAround = React.lazy(() =>
  import('../components/LookAround/LookAround')
);
const MyDesk = React.lazy(() => import('../components/MyDesk'));
const OurPrecious = React.lazy(() => import('../components/OurPrecious'));
const Shipsymates = React.lazy(() => import('../components/Shipsymates'));
const ShipsyinNews = React.lazy(() => import('../components/ShipsyinNews'));
const Chillax = React.lazy(() => import('../components/Chillax'));
const BeerforCheer = React.lazy(() => import('../components/BeerforCheer'));
const Gallery = React.lazy(() => import('../components/Gallery'));
const SujhavPeti = React.lazy(() => import('../components/SujhavPeti'));
const Suspense = React.Suspense;

const RouterOutlet = (props: any) => {
  return (
    <Suspense
      fallback={
        <div style={{ margin: '28% 0 0 50%' }}>
          {/* <img src={Loader} alt='' /> */}
        </div>
      }
    >
      <Switch>
        <Route
          path={getCreateProfileRoute()}
          exact
          component={CreateProfilePage}
        />
        <Route path={getMyDeskRoute()} exact component={MyDesk} />
        <Route path={getLookAroundRoute()} exact component={LookAround} />
        <Route path={getOurPreciousRoute()} exact component={OurPrecious} />
        <Route path={getShipsymatesRoute()} exact component={Shipsymates} />
        <Route path={getShipsyinNewsRoute()} exact component={ShipsyinNews} />
        <Route path={getChillaxRoute()} exact component={Chillax} />
        <Route path={getBeerforCheerRoute()} exact component={BeerforCheer} />
        <Route path={getGalleryRoute()} exact component={Gallery} />
        <Route path={getSujhavPetiRoute()} exact component={SujhavPeti} />
        <Route path={getPolicyRoute()} exact component={Policy} />
        <Route component={DefaultPage} />
      </Switch>
    </Suspense>
  );
};

export default RouterOutlet;

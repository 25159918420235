import moment from 'moment';
const currentYear = moment()?.format('YYYY');

export const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
};

export const dataHeaders: Record<string, string> = {
  JPG: 'image/*',
  JPEG: 'image/*',
  PNG: 'image/*',
  PDF: 'application/*',
  DOCX: 'application/*',
  XLSX: 'application/*',
  CSV: 'text/csv',
  XLS: 'application/*',
  DOC: 'application/*',
};

const quarterRange: Record<string, { start: string; end: string }> = {
  Q1: {
    start: `${currentYear}-04-01`,
    end: `${currentYear}-06-30`,
  },
  Q2: {
    start: `${currentYear}-07-01`,
    end: `${currentYear}-09-30`,
  },
  Q3: {
    start: `${currentYear}-10-01`,
    end: `${currentYear}-12-31`,
  },
  Q4: {
    start: `${currentYear}-01-01`,
    end: `${currentYear}-03-31`,
  },
};

export const getCurrentQuarter = (): string => {
  const currentDate = moment()?.format('YYYY-MM-DD');
  let currentQuarter = '';
  Object.keys(quarterRange)?.some((quarter: string) => {
    return quarterRange[quarter]?.start <= currentDate &&
      quarterRange[quarter]?.end >= currentDate
      ? ((currentQuarter = quarter), true)
      : false;
  });
  return currentQuarter;
};

export const logoutUser= ()=>{
	localStorage.clear()
	location.reload();
	console.log("logout");
}
import * as React from 'react';
import withAuth from './hoc/withAuth';
import RouterOutlet from './routing/RouterOutlet';
import {
  getCreateProfileRoute,
  getLoginRoute,
  getLogoutRoute,
} from './routing/utils';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

class App extends React.PureComponent<any, any> {
  render() {
    const { authenticated, forceLogout, isProfileCreated } = this.props;
    if (forceLogout) {
      return <Redirect to={{ pathname: getLogoutRoute() }} />;
    }
    if (!authenticated) {
      return <Redirect to={{ pathname: getLoginRoute() }} />;
    }
    // if (!isProfileCreated) {
    //   return <Redirect to={{ pathname: getCreateProfileRoute() }} />;
    // }
    return <RouterOutlet />;
  }
}

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  forceLogout: loginReducer?.forceLogout,
});

export default connect(mapStateToProps)(withAuth(App));

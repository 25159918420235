import * as React from 'react';
import withStyles from 'react-jss';
import myDeskSelected from '../icons/myDeskSelected.svg';
import myDeskunSelected from '../icons/myDeskunSelected.svg';
import lookaroundselected from '../icons/lookaroundselected.svg';
import lookaroundunselected from '../icons/lookaroundunselected.svg';
import ourPreciousselected from '../icons/ourPreciousselected.svg';
import ourPreciousunselected from '../icons/ourPreciousunselected.svg';
import shipsymatesselected from '../icons/shipsymatesselected.svg';
import shipsymatesunselected from '../icons/shipsymatesunselected.svg';
import newsselected from '../icons/newsselected.svg';
import newsunselected from '../icons/newsunselected.svg';
import chillaxselected from '../icons/chillaxselected.svg';
import chillaxunselected from '../icons/chillaxunselected.svg';
import bfcselected from '../icons/bfcselected.svg';
import bfcunselected from '../icons/bfcunselected.svg';
import galleryselected from '../icons/galleryselected.svg';
import galleryunselected from '../icons/galleryunselected.svg';
import sujhavselected from '../icons/sujhavselected.svg';
import sujhavunselected from '../icons/sujhavunselected.svg';
import defaultprofilepic from '../icons/profilepic.png';
import ShipsyverseIcon from '../icons/shipsyverse.gif';
import policySelected from '../icons/Selected - Policy.svg';
import policyUnSelected from '../icons/Not Selected - Policy.svg';
import { useGenericState } from '../library/UseGenericState';
import {
  getBeerforCheerRoute,
  getChillaxRoute,
  getGalleryRoute,
  getLookAroundRoute,
  getMyDeskRoute,
  getOurPreciousRoute,
  getPolicyRoute,
  getShipsyinNewsRoute,
  getShipsymatesRoute,
  getSujhavPetiRoute,
} from '../routing/utils';
import { logoutUser } from './utils';

const styles = {
  parentDiv: {
    height: '90vh',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '24px',
  },
  header: {
    backgroundColor: '#43A9CE',
    height: '72px',
    borderRadius: '8px 8px 0 0',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    backgroundColor: '#005198',
    height: '663px',
    borderRadius: '0 0 8px 8px',
    padding: '12px 0 24px 28px',
    color: '#FFFFFF',
  },
  profilePic: {
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    border: '3px solid #FFFFFF',
  },
  name: {
    fontSize: '16px',
    color: '#FFF',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100px',
  },
};

export const sideMenu = [
  {
    title: 'My Desk',
    selectedIcon: myDeskSelected,
    unselectedIcon: myDeskunSelected,
  },
  {
    title: 'Look Around',
    selectedIcon: lookaroundselected,
    unselectedIcon: lookaroundunselected,
  },
//   {
//     title: 'Our Precious',
//     selectedIcon: ourPreciousselected,
//     unselectedIcon: ourPreciousunselected,
//   },
//   {
//     title: 'Shipsymates',
//     selectedIcon: shipsymatesselected,
//     unselectedIcon: shipsymatesunselected,
//   },
//   {
//     title: 'Shipsy in News',
//     selectedIcon: newsunselected,
//     unselectedIcon: newsselected,
//   },
  {
    title: 'Chillax',
    selectedIcon: chillaxselected,
    unselectedIcon: chillaxunselected,
  },
  {
    title: 'Beer for Cheer',
    selectedIcon: bfcselected,
    unselectedIcon: bfcunselected,
  },
  {
	title: 'Logout',
    selectedIcon: bfcselected,
    unselectedIcon: bfcunselected,
  }
//   {
//     title: 'Gallery',
//     selectedIcon: galleryselected,
//     unselectedIcon: galleryunselected,
//   },
//   {
//     title: 'Sujhav Peti',
//     selectedIcon: sujhavselected,
//     unselectedIcon: sujhavunselected,
//   },
//   {
//     title: 'Policy',
//     selectedIcon: policySelected,
//     unselectedIcon: policyUnSelected,
//   }
];

const menuToComponentMap = {
  'My Desk': getMyDeskRoute(),
  'Look Around': getLookAroundRoute(),
  'Our Precious': getOurPreciousRoute(),
  Shipsymates: getShipsymatesRoute(),
  'Shipsy in News': getShipsyinNewsRoute(),
  Chillax: getChillaxRoute(),
  'Beer for Cheer': getBeerforCheerRoute(),
  Gallery: getGalleryRoute(),
  'Sujhav Peti': getSujhavPetiRoute(),
  'Policy':getPolicyRoute()
};

const SideDrawer = (props: any) => {
  const { classes, currentPage, history } = props;

  const profilePicUrl = localStorage.getItem('profilePicUrl');
  const name = localStorage.getItem('name')?.split(' ')?.[0];
  const city = localStorage.getItem('city');

  const handleMenuChange = (menuItem) => {
	if(menuItem=='Logout'){
		logoutUser();
	}
    if (menuToComponentMap[menuItem] && menuItem !== currentPage) {
      history.push(menuToComponentMap[menuItem]);
    }
  };

  return (
    <div className={classes.parentDiv}>
      <div className={classes.header}>
        <img
          src={profilePicUrl || defaultprofilepic}
          alt=''
          className={classes.profilePic}
          style={{ marginRight: '12px' }}
        />
        <div>
          <div className={classes.name}>{name}</div>
          <div
            className={classes.name}
            style={{
              fontSize: '12px',
              fontWeight: 'normal',
              color: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            {city!=='undefined' ?city:''}
          </div>
        </div>
      </div>
      <div className={classes.menu} style={{ position: 'relative' }}>
        {sideMenu.map((menu, index) => (
          <div
            key={index}
            style={{
              padding: '16px 0',
              cursor: 'pointer',
              borderRight:
                currentPage === menu.title ? '8px solid #F79151' : 'none',
            }}
          >
            <img
              src={
                currentPage === menu.title
                  ? menu.selectedIcon
                  : menu.unselectedIcon
              }
            />
            <span
              style={{
                fontWeight: currentPage === menu.title ? 600 : 'normal',
                color:
                  currentPage === menu.title
                    ? '#FFFFFF'
                    : 'rgba(255, 255, 255, 0.8)',
                paddingLeft: '10px',
                fontSize: '12px',
              }}
              onClick={() => handleMenuChange(menu.title)}
            >
              {menu.title}
            </span>
          </div>
        ))}
        <div>
          <img
            src={ShipsyverseIcon}
            alt=''
            style={{ position: 'absolute', bottom: '24px', left: '37%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SideDrawer);

import apiProvider from './provider';
import {
  getAuthenticationHeaders,
  getErrorMessage,
} from './utils';
import store from '../store/appStore';
import { FORCE_LOGOUT } from '../components/action.constants';
import { logoutUser } from '../components/utils';

export const GET = async (
  url: string,
  params?: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      headers,
    });
    return {
      isSuccess: true,
      data: response?.data,
      status: response.status,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      logoutUser();
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true,
      });
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};


export const POST = async (
  url: string,
  body: any,
  authorize: boolean = true,
  headers = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const meta = {
      headers,
    };
    const response = await apiProvider.post(url, body, authorize ? meta : {});
    const dataToReturn = {
      isSuccess: true,
      data: response?.data,
    };
    return dataToReturn;
  } catch (err) {
    if (authorize && err?.response?.status === 401) {
	  logoutUser();
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true,
      });
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const PUT = async (
  url: string,
  file: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    await apiProvider.put(url, file, { headers });
    return {
      isSuccess: true,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
	  logoutUser();
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true,
      });
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const DELETE = async (
  url: string,
  params?: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const response = await apiProvider.delete(url, {
      params,
      headers,
    });
    return {
      isSuccess: true,
      data: response?.data,
      status: response.status,
    };
  } catch (err) {
    if (err?.response?.status === 401) {
	  logoutUser();
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true,
      });
      return {
        isSuccess: false,
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

import { Col, Row } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { withRouter } from 'react-router';
import { useGenericState } from '../library/UseGenericState';
import SideDrawer from './SideDrawer';
import Loader from '../icons/Loader.gif';
import MyApps from './MyApps';
import Birthday_Half_Day from '../icons/Policies/Birthday Half Day.png';
import Leave_Policy from '../icons/Policies/Leave Policy.png';
import New_Parent_Leave_Policy from '../icons/Policies/New parent leave policy.png';
import KENKO_Insurance_Handbook from '../icons/Policies/Kenko Insurance SOP.png';
import KEKA_SOP_1_for_Employee from '../icons/Policies/Keka - For Employee Performance.png';
import KEKA_SOP_2_for_VCM_s from '../icons/Policies/Kenko Insurance SOP.png';
import Policy_Handbook from '../icons/Policies/Policy Handbook.png';
// import POSH_Policy from '../icons/Policies/';
import Shipsy_Asset_Purchase from '../icons/Policies/Shipsy Asset Purchase.png';
import Shipsy_Learning_Scholarship_Program from '../icons/Policies/Shipsy Learning Scholarship Program.png';
import Shipsy_Referral_Policy_November_2021 from '../icons/Policies/Shipsy Referral Policy.png';
import Shipsy_Sports_Program from '../icons/Policies/Shipsy Sports Program.png';
import Shipsy_Welfare_Scheme_2021 from '../icons/Policies/Shipsy Welfare Scheme.png';
import SOP_Exit_Module from '../icons/Policies/SOP - Exit Module.png';
import SOP_Expenses_Reimbursement_Module_on_KEKA from '../icons/Policies/SOP - Expenses & Reimbursement Module on KEKA.png';
import SOP_Helpdesk_Module from '../icons/Policies/SOP - Helpdesk Module.png';
import SOP_Investment_Declaration_on_KEKA from '../icons/Policies/SOP - Investment declaration.png';
import SOP_JIRA from '../icons/Policies/SOP - JIRA.png';
import SOP_KEKA_Software from '../icons/Policies/SOP - KEKA Software.png';
import SOP_Laptop_Process from '../icons/Policies/SOP - Laptop Process.png';
import SOP_Leave_process_on_KEKA from '../icons/Policies/SOP - Leave process.png';
import SoP_New_Salary_Structure_FBP from '../icons/Policies/SOP - New Salary structure-1.png';
import SOP_Offboarding_Process from '../icons/Policies/SOP - Offboarding.png';
import SOP_Background_Verification from '../icons/Policies/SOP - Background verification.png';
import SOP_Employee_Engagement from '../icons/Policies/SOP - Employee Engagement.png';
import SOP_Probation_Process from '../icons/Policies/SOP - Probation Process.png';
import Team_Engagement_Policy_2021 from '../icons/Policies/Team Engagement Policy.png';
import New_FAQ_People_Success from '../icons/Policies/FAQ-People Success.png';

import { fetchMyDeskData } from '../api/apis';

const styles = {};

const Policy = (props) => {
  const { classes, history } = props;
  const [state, setState] = useGenericState<any>({
    isFetching: false,
    bfcMyApps: [],
  });
  const { isFetching, bfcMyApps } = state;
  React.useEffect(() => {
    fetchMyDesk();
  }, []);

  const fetchMyDesk = async () => {
    setState({ isFetching: true });
    let myApps;
    const response = await fetchMyDeskData();
    if (response?.isSuccess) {
      myApps = response?.data?.myApps || {};
    }

    myApps = myApps.filter((app) => app.isAdded === true);

    setState({
      isFetching: false,
      bfcMyApps: [...myApps],
    });
  };
  // Codenames https://codenames.game/
  // Mutter Nonsense https://vxn.games/library/mutter-nonsense
  // Phonic Frenzy https://vxn.games/library/phonic-frenzy
  // Speak Easy https://vxn.games/library/speak-easy
  // Geo User https://www.geoguessr.com/
  // Kahoot https://create.kahoot.it/auth/login
  // Jigsaw https://jigsawpuzzles.io/
  // Chess https://www.chess.com/
  const PolicyData = [
    {
      image: Birthday_Half_Day,
      name: 'Birthday Half Day',
      link:
        'https://docs.google.com/document/d/1jfjpn-6dFnSuuT4YRBv0mPD-dyCmJzrVhQ5H_HO-Ggs/edit?usp=sharing',
    },
    {
      image: Leave_Policy,
      name: 'Leave Policy',
      link:
        'https://docs.google.com/document/d/1_7VCcbYXg_irn3v5JmcklyBo-w6K2oqiStYYIUBVwDk/edit?usp=sharing',
    },
    {
      image: New_Parent_Leave_Policy,
      name: 'New Parent Leave Policy',
      link:
        'https://drive.google.com/file/d/1oEx4h1yZ18UIVCfUFEN72utdbNwPrK_X/view?usp=sharing',
    },
    {
      image: KENKO_Insurance_Handbook,
      name: 'KENKO Insurance Handbook',
      link:
        'https://docs.google.com/presentation/d/1XA0S5zxn_KodUdJjJToPX-xTiCOt95uO/edit?usp=sharing&ouid=108081821540814032524&rtpof=true&sd=true',
    },
    {
      image: KEKA_SOP_1_for_Employee,
      name: 'KEKA SOP-1 for Employee',
      link:
        'https://docs.google.com/document/d/1nb_Ay1RotdehAT5lognEF8cp8FvN0OEKeTgMUF6e0N8/edit?usp=sharing',
    },
    {
      image: KEKA_SOP_2_for_VCM_s,
      name: "KEKA SOP-2 for VCM's",
      link:
        'https://docs.google.com/document/d/13uQFC4EvMwAOq0_dYnfxHqfwpdnanO1rpMyxrBv44Zw/edit?usp=sharing',
    },
    {
      image: Policy_Handbook,
      name: 'Policy Handbook',
      link:
        'https://drive.google.com/file/d/1ijgQXvJXb3-7x7FJ__YnuBNmzC_cPQ9S/view?usp=sharing',
    },
    // {
    //   image: POSH_Policy,
    //   name: 'POSH Policy',
    //   link:
    //     'https://drive.google.com/file/d/1ijgQXvJXb3-7x7FJ__YnuBNmzC_cPQ9S/view?usp=sharing',
    // },
    {
      image: Shipsy_Asset_Purchase,
      name: 'Shipsy Asset Purchase',
      link:
        'https://docs.google.com/document/d/1veg7_cSJ6jQonuvYaQ9xPN0BQtapFawbm5SWY0envbY/edit?usp=sharing',
    },
    {
      image: Shipsy_Learning_Scholarship_Program,
      name: 'Shipsy Learning Scholarship Program',
      link:
        'https://docs.google.com/document/d/19OlXi7rlx71jIe6h-nyomcIja0Yd2kdSyCZwY6T7n90/edit?usp=sharing',
    },
    {
      image: Shipsy_Referral_Policy_November_2021,
      name: 'Shipsy Referral Policy - November 2021',
      link:
        'https://docs.google.com/document/d/1cGhk2ocIuUTCHHCk2J-0HWu3gCjcFV6UQ1jn_DlF-fI/edit?usp=sharing',
    },
    {
      image: Shipsy_Sports_Program,
      name: 'Shipsy Sports Program',
      link:
        'https://docs.google.com/document/d/1wjhDelRntlvSvBL7_XswFQwqTN3kEo-_7wYPqEFj860/edit?usp=sharing',
    },
    {
      image: Shipsy_Welfare_Scheme_2021,
      name: 'Shipsy Welfare Scheme 2021',
      link:
        'https://docs.google.com/document/d/1KJ-EQSr5xpSJtbBDXce7C_naII4k2HWKEhkfHbtGd2A/edit?usp=sharing',
    },
    {
      image: SOP_Exit_Module,
      name: 'SOP - Exit Module',
      link:
        'https://docs.google.com/document/d/1N2loHvnqYDcsam9Sk6oqDVpLFZxNYeZjVHkUuR0v2xg/edit?usp=sharing',
    },
    // {
    //   image: SOP_Expenses_ & _Reimbursement_Module_on_KEKA,
    //   name: 'SOP - Expenses & Reimbursement Module on KEKA',
    //   link:
    //     'https://docs.google.com/document/d/1EIuI06lRA0Y-qclkZpa_BjDuQneopaM8jKE0PzYL1U4/edit?usp=sharing',
    // },
    {
      image: SOP_Helpdesk_Module,
      name: 'SOP - Helpdesk Module',
      link:
        'https://docs.google.com/document/d/1k2xNi-TtbXdX0LVNF-14ZHRgb8kOR2yrhi15mI4vBbg/edit?usp=sharing',
    },
    {
      image: SOP_Investment_Declaration_on_KEKA,
      name: 'SOP - Investment Declaration on KEKA',
      link:
        'https://docs.google.com/document/d/1Ox4NoNuA6QOFySWnjEjrpyNr4bOh4VzXu3fNb9iFtOk/edit?usp=sharing',
    },
    {
      image: SOP_JIRA,
      name: 'SOP - JIRA',
      link:
        'https://docs.google.com/document/d/1TzcrKQz-TSSyxlKG8RbTJN4Ea62-j4Fdwc9IGHbFElo/edit?usp=sharing',
    },
    {
      image: SOP_KEKA_Software,
      name: 'SOP - KEKA Software',
      link:
        'https://docs.google.com/document/d/105XTgw8VizEPZqO0GVxEB8YBNZYcokdGoL5KhtSx0Vw/edit?usp=sharing',
    },
    {
      image: SOP_Laptop_Process,
      name: 'SOP - Laptop Process',
      link:
        'https://docs.google.com/document/d/1i340K25sm1UN68Jac_oGjXrQFjOkK_loAOq-YhN4fdw/edit?usp=sharing',
    },
    {
      image: SOP_Leave_process_on_KEKA,
      name: 'SOP - Leave process on KEKA',
      link:
        'https://docs.google.com/document/d/1a9Tb0MJjGzmhK8GeBNcibyddimlYZyOpoS8Dk0wOAyk/edit?usp=sharing',
    },
    // {
    //   image: SoP_New_Salary_Structure_FBP,
    //   name: 'SoP - New Salary Structure - FBP',
    //   link:
    //     'https://docs.google.com/document/d/1UkmitDbbP43HxwxrBXmfv5_36tMRWnCMAzdYeikOCJU/edit?usp=sharing',
    // },
    {
      image: SoP_New_Salary_Structure_FBP,
      name: 'SoP - New Salary Structure - FBP',
      link:
        'https://docs.google.com/document/d/1xAcQDIPYQ7c8lm9KpYDEIRcbeXJvpFsD4aPi2MmDsDg/edit?usp=sharing',
    },
    {
      image: SOP_Offboarding_Process,
      name: 'SOP - Offboarding Process',
      link:
        'https://docs.google.com/document/d/1Ov7hncrBS30Cm1Cbv8_fFAPQoh4Ayo08ebnoNtEoRKw/edit?usp=sharing',
    },
    {
      image: SOP_Background_Verification,
      name: 'SOP - Background Verification',
      link:
        'https://docs.google.com/document/d/1Bs3-mMLuu1Tdqh0Ha4L9PDCXFLmkWiVP5OmmnYdtSyo/edit?usp=sharing',
    },
    {
      image: SOP_Employee_Engagement,
      name: 'SOP - Employee Engagement',
      link:
        'https://docs.google.com/document/d/1Qk-sp3yK-hmBn6616xLcjMuGYRiWXnoPSmccCOzGK8w/edit?usp=sharing',
    },
    {
      image: SOP_Probation_Process,
      name: 'SOP - Probation Process',
      link:
        'https://docs.google.com/document/d/1VKf2bRvUi-8g0VpqftlOWhYgkiBs3KSVD0bW9dxs3Z8/edit?usp=sharing',
    },
    {
      image: Team_Engagement_Policy_2021,
      name: 'Team Engagement Policy 2021',
      link:
        'https://docs.google.com/document/d/1gPm51nDdEoEotECE4gBoljcKzfR-Q6xZioAYla5SU_A/edit?usp=sharing',
    },
    {
      image: New_FAQ_People_Success,
      name: 'New FAQ - People Success',
      link:
        'https://docs.google.com/spreadsheets/d/1Tna0O75bQ0SIcDhIwwptfMRuTeP8cpfGS1Ov1l_9NmI/edit?usp=sharing',
    },
  ];
  return (
    <Row style={{ padding: '24px', display: 'flex', height: '100vh' }}>
      <Col span={4}>
        <SideDrawer currentPage='Policy' history={history} />
      </Col>
      <Col span={20} style={{ overflow: 'scroll' }}>
        <MyApps apps={bfcMyApps} />
        <Row>
          {PolicyData.map((data) => {
            return (
              <Col
                span={8}
                style={{ paddingRight: '30px', marginBottom: '36px' }}
              >
                <div
                  style={{
                    border: '1px solid #F9F9F9',
                    boxShadow: '3px 3px 10px #E0E0E0',
                    padding: '18px 0px 18px 18px',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <a href={data.link} target='_blank'>
                    <img src={data.image} />
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#111111',
                        marginTop: '12px',
                      }}
                    >
                      {data.name}
                    </div>
                  </a>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(withStyles(styles)(Policy));

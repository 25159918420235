export const GET_S3_UPLOAD_LINKS = '/upload-file';
export const LOGIN = '/slack-oauth/callback';
export const CREATE_PROFILE = '/user';
export const MY_DESK = '/my-desk';
export const FETCH_RANK = '/users/rank';
export const FETCH_CHEERS_FEED = '/cheers-feed';
export const EDIT_MY_DESK = CREATE_PROFILE;
export const ADD_ALARMS = '/alarms';
export const FETCH_USERS = '/users';
export const ADD_TODOS = '/todo';
export const ADD_EVENT = '/events';
export const ADD_SHEETS = '/sheet';
export const ADD_AWARDS = '/award';
export const ADD_COURSES = '/activity';
export const DELETE_SHIPSY_TODO = '/shipsy-todo';
export const CHAI_CONNECT = '/chai-sutta/connect';
export const CHAI_LINK = '/chai-sutta/link';

export const LOOK_AROUND_DASHBOARD = '/look-around/dashboard';
export const GET_ALL_USERS = '/look-around/users';
export const CREATE_WORK_ANNIVERSARY = '/look-around/work-anniversary';
export const CREATE_BFC_CHAMPIONS = '/look-around/bfc-champions';
export const CREATE_STAR_PERFORMERS = '/look-around/star-performers';
export const CREATE_SPECIAL_MENTIONS = '/look-around/special-mentions';
export const CREATE_PRODUCT_RELEASE = '/look-around/product-release';
export const CREATE_CELBRATIONS = '/look-around/celebrations';
export const CREATE_STANDUP = '/look-around/standup';

import queryString from 'querystring';
import React, { useEffect, ReactElement } from 'react'; // eslint-disable-line
import url from 'url';
import { login } from '../api/apis';
import {
  getCreateProfileRoute,
  getLoginRoute,
  getMyDeskRoute,
} from '../routing/utils';
// import Loader from '../cheerIcons/Loader.gif';
import { Redirect, RouterProps } from 'react-router'; // eslint-disable-line
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserDetails } from './Login.actions';

interface IProps extends RouterProps {
  setUserDetails: (details: any) => void;
}

const GAuthPage = (props: IProps): ReactElement => {
  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line

  const { history } = props;

  const setStorage = (values: any) => {
    const {
      id,
      name,
      token,
      profilePicUrl,
      role,
      teamId,
      email,
      city,
      isProfileCreated,
    } = values;
    const storage = window.localStorage;
    storage.setItem('id', id);
    storage.setItem('name', name);
    storage.setItem('email', email);
    if (teamId) storage.setItem('teamId', teamId);
    if (token) storage.setItem('authToken', token);
    storage.setItem('profilePicUrl', profilePicUrl);
    storage.setItem('role', role);
    storage.setItem('city', city);
    storage.setItem('isProfileCreated', isProfileCreated);
    storage.setItem('lastActionTime', String(new Date()));
  };

  const initialize = async () => {
    const { location } = window;

    if (!location) {
      return history.push(getLoginRoute());
    }
    const { href = '' } = location;
    const { query } = url.parse(href);

    if (query) {
      const parsedQuery = queryString.parse(query);
      const { code, error = '' } = parsedQuery;
      const reqBody = {
        code: (code as string) || '',
        error: (error as string) || '',
      };

      const response = await login(reqBody);
      if (response.isSuccess && response.data) {
        const {
          id,
          name,
          email,
          profilePicUrl,
          isProfileCreated,
          token,
          city,
          role,
          teamId,
        } = response.data;
        setStorage({
          id,
          name,
          email,
          profilePicUrl,
          isProfileCreated:isProfileCreated || false,
          token,
          role,
          teamId,
          city,
        });
        if (!isProfileCreated) {
          history.push(getCreateProfileRoute());
        } else {
          history.push(getMyDeskRoute());
        }
      } else {
        return history.replace(getLoginRoute(), {
          ...window.history.state,
          error: response.errorMessage,
        });
      }
    }
  };

  return (
    <div style={{ margin: '15% 0 0 48%' }}>
      {/* <img src={Loader} alt="" /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUserDetails,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(GAuthPage);

import { DELETE, GET, POST, PUT } from './apiHandler';
import {
  ADD_ALARMS,
  ADD_AWARDS,
  ADD_COURSES,
  ADD_EVENT,
  ADD_SHEETS,
  ADD_TODOS,
  CHAI_CONNECT,
  CHAI_LINK,
  CREATE_BFC_CHAMPIONS,
  CREATE_CELBRATIONS,
  CREATE_PRODUCT_RELEASE,
  CREATE_PROFILE,
  CREATE_SPECIAL_MENTIONS,
  CREATE_STANDUP,
  CREATE_STAR_PERFORMERS,
  CREATE_WORK_ANNIVERSARY,
  DELETE_SHIPSY_TODO,
  EDIT_MY_DESK,
  FETCH_CHEERS_FEED,
  FETCH_RANK,
  FETCH_USERS,
  GET_ALL_USERS,
  GET_S3_UPLOAD_LINKS,
  LOGIN,
  LOOK_AROUND_DASHBOARD,
  MY_DESK,
} from './routes';
import { API_BASE_URL, EXTERNAL_BASE_URL } from '../globals';
import { dataHeaders } from '../components/utils';

export const login = async (params: { code?: string; error?: string }) => {
  return await POST(`${API_BASE_URL}${LOGIN}`, params, false);
};

export const getS3Links = async (body) => {
  return await POST(`${API_BASE_URL}${GET_S3_UPLOAD_LINKS}`, body);
};

export const createProfile = async (body) => {
  return await POST(`${API_BASE_URL}${CREATE_PROFILE}`, body);
};

export const updateProfile = async (body) => {
  return await POST(`${API_BASE_URL}${CREATE_PROFILE}`, body);
};

export const fetchMyDeskData = async () => {
  return await GET(`${API_BASE_URL}${MY_DESK}`);
};

export const getChaiLink = async () => {
  return GET(`${API_BASE_URL}${CHAI_LINK}`);
};

export const editMyDesk = async (params) => {
  return await POST(`${API_BASE_URL}${EDIT_MY_DESK}`, params);
};

export const addAlarms = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_ALARMS}`, params);
};

export const addTodos = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_TODOS}`, params);
};

export const addEvent = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_EVENT}`, params);
};
export const addSheets = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_SHEETS}`, params);
};
export const addAwards = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_AWARDS}`, params);
};

export const addCourses = async (params) => {
  return await POST(`${API_BASE_URL}${ADD_COURSES}`, params);
};

export const editLookAround = async (params) => {
  return await POST(`${API_BASE_URL}${LOOK_AROUND_DASHBOARD}`, params);
};

export const connectChai = async () => {
  return POST(`${API_BASE_URL}${CHAI_CONNECT}`, {});
};

export const createWorkAnniversary = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_WORK_ANNIVERSARY}`, params);
};

export const createBfcChampions = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_BFC_CHAMPIONS}`, params);
};

export const createStarPerformers = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_STAR_PERFORMERS}`, params);
};

export const createSpecialMentions = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_SPECIAL_MENTIONS}`, params);
};

export const createProductRelease = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_PRODUCT_RELEASE}`, params);
};

export const createCelebrations = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_CELBRATIONS}`, params);
};

export const createStandup = async (params) => {
  return POST(`${API_BASE_URL}${CREATE_STANDUP}`, params);
};

export const deleteSheets = async (params) => {
  return await DELETE(`${API_BASE_URL}${ADD_SHEETS}`, params);
};

export const deleteTodo = async (params) => {
  return await DELETE(`${API_BASE_URL}${ADD_TODOS}`, params);
};

export const deleteAlarms = async (params) => {
  return await DELETE(`${API_BASE_URL}${ADD_ALARMS}`, params);
};

export const deleteCourse = async (params) => {
  return await DELETE(`${API_BASE_URL}${ADD_COURSES}`, params);
};

export const deleteEvent = async (params) => {
  return await DELETE(`${API_BASE_URL}${ADD_EVENT}`, params);
};

export const deleteShipsyTodos= async (params) => {
  return await DELETE(`${API_BASE_URL}${DELETE_SHIPSY_TODO}`, params);
};

export const deleteProductRelease = async (params) => {
  return await DELETE(`${API_BASE_URL}${CREATE_PRODUCT_RELEASE}`, params);
};
export const deletePersonalMilestone = async (params) => {
  return await DELETE(`${API_BASE_URL}${CREATE_CELBRATIONS}`, params);
};

export const deleteWorkAnniversary = async (params) => {
  return await DELETE(`${API_BASE_URL}${CREATE_WORK_ANNIVERSARY}`, params);
};



export const uploadToS3 = async (
  url: string,
  extension: string,
  file: File
) => {
  return PUT(url, file, {
    'Content-Type': dataHeaders[extension.toUpperCase()],
  });
};

export const fetchRank = async () => {
  return GET(`${EXTERNAL_BASE_URL}${FETCH_RANK}`);
};

export const fetchCheersFeed = async (params?: any) => {
  return GET(`${EXTERNAL_BASE_URL}${FETCH_CHEERS_FEED}`, params);
};

export const fetchAllUsers = async () => {
  return await GET(`${API_BASE_URL}${GET_ALL_USERS}`);
};

export const fetchLookAround = async () => {
  return await GET(`${API_BASE_URL}${LOOK_AROUND_DASHBOARD}`);
};
